import { instanceToInstance, plainToInstance, type ClassConstructor } from "class-transformer";
import MobileDetect from "mobile-detect";
//import { v1 as uuidv1 } from "uuid";
import path from "path";
import short from "short-uuid";
import { ConnKind, AreaKind } from "@/enums";
import moment from "moment";
import "moment/locale/ko";
import { number } from "echarts";
import AppError from "~/models/errors/app-error";
import { PageInfo } from "~/models/core-types";
import { useCommonCodeStore } from "~/store/commonCodeStore";

class CommonUtils {

	/**
	 * ID 를 생성한다. (중간에 비어 있는 수가 있으면 비어 있는 수를 리턴)
	 * @param numbers
	 * @returns
	 */
	static getNewId(numbers: number[]) {
		if (numbers.length <= 0) return 1;

		numbers.sort((a, b) => {
			return a - b;
		});

		//빈값 찾기
		for (let i = 0; i < numbers.length; i++) {
			const num = i + 1;

			if (num !== numbers[i]) return num;
		}

		return numbers[numbers.length - 1] + 1;
	}

	/**
	 * ID(Max)를 생성한다.
	 * @param numbers
	 */
	static getNewMaxId(numbers: number[]) {
		if (numbers.length <= 0) return 1;

		return Math.max.apply(null, numbers) + 1;
	}

	/**
	 * UUID를 생성한다.
	 * @returns uuid
	 */
	static generateUUID() {
		/*
    let options = {
      node: // 바이트값 6개
      clockseq: // 클럭 시퀀스 (0 - 0x3fff 사이 값)
      msecs: // 밀리초
      nsecs: // 나노초
      random: // 16개의 랜덤 바이트값
      rng: // random 변수를 대체할 16개의 랜덤 바이트값을 반환하는 함수
    } 
    */
		//return uuid.v1();
		//return uuidv1();
		return short.generate();
	}

	/**
	 * 객체를 복제한다.
	 * @param obj 객체 (클래스)
	 * @returns 복제된 객체
	 */
	static deepClone<T>(obj: T) {
		return instanceToInstance<T>(obj);
	}

	static toJson(obj: object) {
		return JSON.stringify(obj, null, 2);
	}

  static innerCoordSet(coord:Array<any>){
    let result = [];
    for(let i = 0; i < coord.length; i++){
      if(coord[i].length === 2 && typeof coord[i][0] === 'number' && typeof coord[i][1] === 'number'){
        result.push(coord[i]);
      } else {
        result.push(this.innerCoordSet(coord[i]));
      }
    }
    return result;
  }

  static innerCoordLoop(coord: Array<any>, kakaoFunction: any){
    // console.log(coord)

    // let result = [];

    // let coordResult = this.innerCoordSet(coord);

    // console.log(coordResult);

    // let newCoord = [];

    // for(let i = 0; i < coordResult.length; i++){
    //   newCoord.push(coordResult[i][0]);
    // }
     
    // for(let i = 0; i < newCoord.length; i++){
    //   let newResult = [];
    //   for(let j = 0; j < newCoord[i].length; j++){
    //     newResult.push(new kakaoFunction.maps.LatLng(newCoord[i][j][0], newCoord[i][j][1]));
    //   }
    //   result.push(newResult);
    // }

    //--------------------
    
    // let result = [];

    // if(coord.length === 1){
    //   result.push(this.innerCoordLoop(coord[0], kakaoFunction));
    // } else {

    //   for(let i = 0; i < coord.length; i++){
    //     if(coord[i].length === 2 && typeof coord[i][0] === 'number' && typeof coord[i][1] === 'number'){
    //       result.push(new kakaoFunction.maps.LatLng(coord[i][0], coord[i][1]));
    //     } else {
    //       result.push(this.innerCoordLoop(coord[i], kakaoFunction));
    //     }
    //   }    
    // }







    //------------------
     
    // return result;


    let result = [] as any[];
    

    if(coord[0].length > 2){
      coord[0] = [coord[0]];
    }
   for(let i = 0; i < coord.length; i++){
      let newResult = [];
      for(let j = 0; j < coord[i][0].length; j++){
        newResult.push(new kakaoFunction.maps.LatLng(coord[i][0][j][1], coord[i][0][j][0]));
      }
      result.push(newResult);
    }

    return result;
  }

	/**
	 * 객체가 비어 있는지 체크 (비어 있으면 true)
	 * @param obj
	 * @returns
	 */
	static isNullOrEmpty<T>(obj: T | null | undefined): obj is null | undefined {
		return obj === null || obj === undefined;
	}

	static isStrEmpty<T>(obj: T) {
		return obj === "";
	}

	static isPrimitive(value: any) {
		return value === null || (typeof value !== "object" && typeof value !== "function");
	}

	static nameof<T>(name: keyof T) {
		return name;
	}

	/**
	 * Json -> 역직렬화
	 * @param cls 타입
	 * @param json Json
	 * @returns
	 */
	static jsonDeserialize<T>(cls: ClassConstructor<T>, json: string): T | T[] {
		try {
			const deserializeObj: T = JSON.parse(json);

			return plainToInstance(cls, deserializeObj);
		} catch (e) {
			throw new Error("Method not implemented.");
		}
	}

	static jsonSerialize<T>(obj: T | null) {
		if (this.isNullOrEmpty(obj)) return "";

		return JSON.stringify(obj, null, 2);
	}

	/**
	 * 배열을 그룹화 한다.
	 * @param array 배열
	 * @param grouper 그룹할 항목
	 * @returns
	 */
	static groupBy<K, V>(array: V[], grouper: (item: V) => K) {
		return array.reduce((store, item) => {
			const key = grouper(item);
			if (!store.has(key)) {
				store.set(key, [item]);
			} else {
				const g = store.get(key);

				if (!this.isNullOrEmpty(g)) g.push(item);
			}
			return store;
		}, new Map<K, V[]>());
	}

	static range(start: number, end: number) {
		return Array.from(Array(end - start + 1).keys()).map((x) => x + start);
	}

	/**
	 * 자리수
	 * @param length 자리수
	 * @param valueString 값
	 * @returns
	 */
	static fillZero(length: number, valueString: string) {
		return valueString.length >= length ? valueString : new Array(length - valueString.length + 1).join("0") + valueString;
	}

	/**
	 * 랜덤값을 만든다.
	 * @param min
	 * @param max
	 * @returns
	 */
	static random(min: number, max: number) {
		return Math.floor(Math.random() * (max - min + 1)) + min;
	}

	/**
	 * 랜덤값을 만든다. (소수점)
	 * @param min
	 * @param max
	 * @returns
	 */
	static dRandom(min: number, max: number) {
		//return parseFloat((Math.random() * (min - max + 1)).toFixed(2));
		return parseFloat((Math.random() * (max - min) + min).toFixed(2));
	}

	/**
	 * 핀번호를 만든다.
	 * @param size 자리수
	 * @returns
	 */
	static getPinNum(size = 6) {
		let pinNum = "";
		for (let i = 0; i < size; i++) {
			pinNum += Math.floor(Math.random() * 10);
		}
		return pinNum;
	}	

	/**
	 * 지정한 열거형의 값에 해당하는지 체크한다.
	 * @param type
	 * @param value
	 * @returns
	 */
	static isEnum<T extends { [s: string]: unknown }>(type: T, value: string | undefined): boolean {
		if (this.isNullOrEmpty(value)) return false;

		const values = Object.values(type);
		return values.includes(value as unknown as T);
	}

	static isNumeric(data: string): boolean {
		return !isNaN(Number(data));
	}

	//사용 안함.
	static convertEnum<T extends { [s: string]: unknown }>(type: T, value: string | undefined): T | undefined {
		try {
			const isEnum = this.isEnum<T>(type, value);

			if (!isEnum) return undefined;
			else return value as unknown as T;
		} catch {
			throw new Error("");
		}
	}

	/**
	 * 파일 이름에서 확장자를 제거한다.
	 * @param fileName 파일 이름
	 * @returns
	 */
	static removeExtName(fileName: string) {
		const extName = path.extname(fileName);
		return path.basename(fileName, extName);
	}

	/**
	 * 파일 이름에서 확장자를 추출한다.
	 * @param fileName 파일 이름
	 * @returns
	 */
	static extractExtName(fileName: string) {
		return fileName.substring(fileName.lastIndexOf(".") + 1).toLowerCase();
	}

	static convertIntEx(s: string | undefined) {
		return this.isNullOrEmpty(s) ? undefined : parseInt(s);
	}

	/**
	 * 숫자의 3자리 마다 , 를 추가한다.
	 * @param number 숫자
	 * @returns
	 */
	static numberWithCommas(number: number, commaNumber: number = 1) {
		if (number === null) return "0";
		var parts = number.toFixed(commaNumber).toString().split(".");
		let result = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") + (parts[1] ? "." + parts[1] : "");
		// if(result.split(".")[1] == '00'){
		//   result = result.split(".")[0];
		// }

		return result;
	}

	/**
	 * 시간 string 포맷 변경
	 * @param date
	 * @returns
	 */
	static timeToString(date: Date, dofw?: boolean) {
		if (!date) {
			return "";
		}
		moment.locale("ko");
		let dayNumber = moment(date).day();
		let koDayOfWeek = ["일", "월", "화", "수", "목", "금", "토"];

		let timeString;

		if (dofw) {
			timeString = moment(date).format(`YYYY-MM-DD(${koDayOfWeek[dayNumber]}) HH:mm`);
		} else {
			timeString = moment(date).format(`YYYY-MM-DD HH:mm`);
		}

		return timeString;
	}

	static getConnKind(header: string | undefined) {
		//헤더가 비어있으면 False(Desktop)
		if (this.isNullOrEmpty(header)) return ConnKind.PC;

		const md = new MobileDetect(header);

		const isMobile = md.phone() !== null || md.mobile() === "UnknownMobile";
		const isTablet = md.tablet() !== null || md.mobile() === "UnknownTablet";

		const result = isMobile || isTablet;
		return result ? ConnKind.Mobile : ConnKind.PC;
	}

	/**
	 * Path에서 QueryString 제거
	 * @param path 경로
	 * @returns
	 */
	static getRemoveQueryString(path: string) {
		if (!path.includes("?")) return path;

		return path.slice(0, path.indexOf("?"));
	}

	/**
	 * 배열 복사 (사용안함 - 프로퍼티가 비어 있음..)
	 * @param sourceArray
	 * @returns
	 */
	static cloneArray<TSource, TTarget>(sourceArray: TSource[]): TTarget[] {
		return sourceArray.map((item) => item as unknown as TTarget);
	}

	/**
	 * Watt -> kWh, MWh 변환
	 * @param watt
	 */
	static wattConvert(watt: number, unit: string) {
		if (unit == "kWh") {
			if (watt > 1000) {
				return {
					value: this.numberWithCommas(watt / 1000, 2),
					unit: "MWh",
				};
			} else if (watt == null) {
				return {
					value: "-",
					unit: "kWh",
				};
			} else {
				return {
					value: this.numberWithCommas(watt, 2),
					unit: "kWh",
				};
			}
		} else if (unit == "kW") {
			if (watt > 1000) {
				return {
					value: this.numberWithCommas(watt / 1000, 2),
					unit: "MW",
				};
			} else if (watt == null) {
				return {
					value: "-",
					unit: "kW",
				};
			} else {
				return {
					value: this.numberWithCommas(watt, 2),
					unit: "kW",
				};
			}
		}
	}

	/**
	 * co2 -> kgCO2, tCO2 변환
	 * @param co2
	 */
	static co2Convert(co2: number) {
		if (co2 > 10000) {
			return {
				value: this.numberWithCommas(co2 / 1000, 2),
				unit: "tCO2",
			};
		} else if (co2 == null) {
			return {
				value: "-",
				unit: "kgCO2",
			};
		} else {
			return {
				value: this.numberWithCommas(co2, 2),
				unit: "kgCO2",
			};
		}
	}

	/**
	 * 주소 노출 텍스트 변환
	 * @param addr
	 * @returns
	 */
	static addrParse(addr: string) {
		let splitAddr = addr.split(" ");
		let parseString = "";

		if (splitAddr[0].length >= 4) {
			parseString += `${splitAddr[0].split("")[0]}${splitAddr[0].split("")[2]}/`;
		} else {
			parseString += `${splitAddr[0].substring(0, 2)}/`;
		}

		parseString += `${splitAddr[1].substring(0, 2)}/${splitAddr[2]}`;

		return parseString;
	}
	/**
	 * 회원상태값에 따른 한글 표기
	 * @param account
	 * made by woonsik
	 */
	static getAccountStatus(account: string) {
		let accountStatus;
		if (account == "active") accountStatus = "사용중";
		else if (account == "lock") accountStatus = "중지";
		else if (account == "leave") accountStatus = "탈퇴";
		else accountStatus = "확인불가";

		return accountStatus;
	}

  static isTimeOrDash(originData: string | null | undefined | Date, dateType: 'hour' | 'day' | 'month'){    
    if(originData === null || originData === undefined){
      return '-';
    }
  
    let type = 'YYYY-MM-DD';

    if(dateType === 'hour') type = 'YYYY-MM-DD HH:mm:ss';
    else if(dateType === 'day') type = 'YYYY-MM-DD';
    else if(dateType === 'month') type = 'YYYY-MM';
    else type = 'YYYY-MM-DD';

    return moment(originData).format(type);
  }

	/**
	 *
	 * @param originData
	 * @param point
	 * 숫자값에 대한 빈값 처리
	 * made by woonsik
	 */
	static isDataOrDash(originData: number | null | undefined, point?: number) {
		let data;
		if (originData !== null && originData !== undefined && !isNaN(originData)) {
			data = this.numberWithCommas(originData as number, point);
		} else {
			data = "-";
		}
		return data;
	}

	/**
	 *
	 * @param originData
	 * 스트링값에 대한 빈값 처리
	 * made by woonsik
	 */
	static isStrDataOrDash(originData: string | null | undefined) {
		let data;
		if (originData !== null && originData !== "" && originData !== undefined) {
			data = originData;
		} else {
			data = "-";
		}
		return data;
	}

	/**
	 * 긴 주소를 짧게 자르는 처리
	 * @param siteAddr
	 * @returns
	 */
	static setAddrString(siteAddr: string) {
		if (!siteAddr) return "";
		let siteSplit = siteAddr?.split(" ") as string[];
		let siteString = "";

		if (siteSplit[0] === "충청북도") siteString = "충북";
		else if (siteSplit[0] === "충청남도") siteString = "충남";
		else if (siteSplit[0] === "강원특별자치도") siteString = "강원";
		else if (siteSplit[0] === "서울특별시") siteString = "서울";
		else siteString = siteSplit[0];

		return `${siteString}/${siteSplit[1]}/${siteSplit[2]}`;
	}

  /**
   * 
   * @param siteAddr 
   */
  static setAddrChart(addrCode: string, siteAddr: string){
    if(!siteAddr) return "";
    let siteSplit = siteAddr.split(" ") as string[];
    let siteString = "";

    if(addrCode.length == 2){
      if(siteSplit[0] === "충청북도") siteString = "충북";
      else if(siteSplit[0] === "충청남도") siteString = "충남";
      else if(siteSplit[0] === "전라남도") siteString = "전남";
      else if(siteSplit[0] === "경상남도") siteString = "경남";
      else if(siteSplit[0] === "경상북도") siteString = "경북";
      else if(siteSplit[0] === "전라북도") siteString = "전북";
      else siteString = siteSplit[0].substring(0,2);
    } else if(addrCode.length === 5){
      siteString = siteAddr;
    } else if(addrCode.length === 10){
      siteString = siteSplit[2];
    }

    return siteString;
  }


	/**
   * 광역시도 또는 시군구 코드로 동코드를 가져온다.
   */
		static cityOrSigunguCodeToDongCode(cityCode: string, sigunguCode?: string) {

			const defaultSigunguCode = "000";
			const defaultDongCode = "00000";
	
			let sc = CommonUtils.isNullOrEmpty(sigunguCode) ? defaultSigunguCode : sigunguCode;
			return `${cityCode}${sc}${defaultDongCode}`;
		}

		static dongCodeToCityOrSigunguCode(dongCode: string, areaKind: AreaKind, isFill = false) {

			if(areaKind === AreaKind.City) {
				const cityCode = dongCode.substring(0, 2); 
				return isFill ? cityCode + "00000000" : cityCode;
			}
			else if (areaKind === AreaKind.Sigungu) {
				const sigunguCode = dongCode.substring(0, 5); 
				return isFill ? sigunguCode + "00000" : sigunguCode;
			}
			else
				return dongCode;
			
			//throw new AppError("지원하지 않는 지역 타입 입니다.")
		}

		static areaToRegionCodes(dongCode: string) {
			
			if(dongCode === "00")
				return ["00", "000", "0000000000"];

			if(dongCode.length !== 10)
				throw new AppError("동코드 길이가 올바르지 않습니다.");

			const cityCode = dongCode.substring(0, 2); 
			const sigunguCode = dongCode.substring(2, 5); 
			
			return [cityCode, sigunguCode, dongCode];
		}

		/**
		 * 페이지 정보 객체를 반환한다.
		 * @param pageNo 페이지 번호
		 * @param pageSize 페이지 사이즈
		 * @param count 총 갯수
		 * @returns 
		 */
		static getPageInfo(pageNo: number, pageSize: number, count: number) {
			const pageCount = Math.floor(count / pageSize);
			const totalPage = (count % pageSize) > 0 ? pageCount + 1 : pageCount;
			const isFirstPage = pageNo <= 1;
			const isLastPage = pageNo >= totalPage;

			const pageInfo: PageInfo = {
				isFirstPage: isFirstPage,
        isLastPage: isLastPage,
        pageNo: pageNo,
        pageSize: pageSize,
        totalPage: totalPage,
			}

			return pageInfo;
		}	

  static routerPush(url: string){
    const router = useRouter();

    router.push(url);
  }

  static getWeather(weatherCondition: number){


  }

  static left(sourceStr:string, findStr:string) {
	let index = sourceStr.indexOf(findStr);
	if (index < 0) { return ""; }
	else { let len = sourceStr.length; return (sourceStr.substring(index+findStr.length, len)); }
  }

  static pad(num:number, size:number) {
	let s = num.toString();
	while (s.length < size) s = "0" + s;
	return s;
  }
}

export default CommonUtils;
