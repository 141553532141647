import { Method } from "~/enums";
import { useLocalStorage } from "@vueuse/core";
import moment from "moment";

import {
	ContractInfo,
	EquipComponent,
	EquipInstallInfo,
	EventInfo,
  EquipInfo,
	FaultTrendInfo,
	ManagerInfo,
	MonitoringByAllInfo,
	MonitoringBySourceInfo,
	OwnerInfo,
	ProdInfoStatus,
	ProdTrend,
	ProdTrendsInfo,
	MobileReportInfo,
} from "~/models/dto-types";
import { useUserStore } from "./userStore";
import { useEtcStore } from "./etcStore";

interface ConvertSiteForm {
	siteName: string;
	ownerSeq: number;
	rtuId: string;
	latitude: number;
	longitude: number;
	siteAddr: string;
	dongCode: string;
	equipList: EquipInfo[];
}

export const useEquipStore = defineStore("equipStore", {
	state: () => ({
		engSourceName: [
			{
				name: "pv",
				key: "10",
			},
			{
				name: "solarheat",
				key: "20",
			},
			{
				name: "geo",
				key: "30",
			},
			{
				name: "wf",
				key: "40",
			},
			{
				name: "wf",
				key: "50",
			},
			{
				name: "fuelcell",
				key: "60",
			},
			{
				name: "ess",
				key: "70",
			},
		],
		equipCid: useLocalStorage("equipCid", "" as string),
		plainEquipList: useLocalStorage("plainEquipList", [] as EquipInfo[]),
		equipInfo: useLocalStorage("equipInfo", []),
		equipDetailInfoList: useLocalStorage("equipDetailInfoList", [] as EquipInfo[]),
		selectEquipMeasure: useLocalStorage("selectEquipMeasure", {}),
		selectEquipInstall: useLocalStorage("selectEquipInstall", {} as EquipInstallInfo),
		selectEquipInfo: useLocalStorage("selectEquipInfo", {} as EquipInfo),
		selectEquipSpec: useLocalStorage("selectEquipSpec", {} as EquipComponent),
		selectEquipRtu: useLocalStorage("selectEquipRtu", {}),
		selectEquipMonitoringSource: useLocalStorage("selectEquipMonitoringSource", {} as MonitoringBySourceInfo),
		mapEquipInfoList: useLocalStorage("mapEquipInfoList", []),
		convertSiteList: useLocalStorage("convertSiteList", [] as ConvertSiteForm[]),
		selectSiteInfo: useLocalStorage("selectSiteInfo", {} as ConvertSiteForm),
	}),
	getters: {
		getEquipCid(state) {
			return state.selectEquipInfo.cid;
		},
	},
	// hydrate(state, initialState) {
	// 	state.equipInfo = useLocalStorage("equipInfo", initialState.equipInfo);
	// 	state.equipCid = useLocalStorage("equipCid", initialState.equipCid);
	// 	state.plainEquipList = useLocalStorage("plainEquipList", initialState.plainEquipList);
	// 	state.equipInfo = useLocalStorage("equipInfo", initialState.equipInfo);
	// 	state.equipDetailInfoList = useLocalStorage("equipDetailInfoList", initialState.equipDetailInfoList);
	// 	state.selectEquipMeasure = useLocalStorage("selectEquipMeasure", initialState.selectEquipMeasure);
	// 	state.selectEquipInstall = useLocalStorage("selectEquipInstall", initialState.selectEquipInstall);
	// 	state.selectEquipInfo = useLocalStorage("selectEquipInfo", initialState.selectEquipInfo);
	// 	state.selectEquipSpec = useLocalStorage("selectEquipSpec", initialState.selectEquipSpec);
	// 	state.selectEquipRtu = useLocalStorage("selectEquipRtu", initialState.selectEquipRtu);
	// 	state.selectEquipMonitoringSource = useLocalStorage("selectEquipMonitoringSource", initialState.selectEquipMonitoringSource);
	// 	state.mapEquipInfoList = useLocalStorage("mapEquipInfoList", initialState.mapEquipInfoList);
	// 	state.convertSiteList = useLocalStorage("convertSiteList", initialState.convertSiteList);
	// 	state.selectSiteInfo = useLocalStorage("selectSiteInfo", initialState.selectSiteInfo);
	// },
	actions: {
    async initEquipList(engSource: string | undefined = undefined, cityCode: string | undefined = undefined, sigunguCode: string | undefined = undefined, dongCode: string | undefined = undefined) {
      console.log('init equip data site ::::');
      if(engSource === null) engSource = undefined;
      if(cityCode === null) cityCode = undefined;
      if(sigunguCode === null) sigunguCode = undefined;
      if(dongCode === null) dongCode = undefined;

      const res = await useCustomFetch<EquipInfo[]>(`/api/v1/equips`, { method: Method.GET, query: { pageNo: 1, pageSize: 5000, engSource, cityCode, sigunguCode, dongCode } });

			let result = [] as EquipInfo[];
			// if (useUserStore().getUserType === "owner") {
			// 	let ownerSeq = useUserStore().loginUserOwnerInfo?.ownerSeq;
			// 	for (let i = 0; i < (res.data.value as EquipInfo[]).length; i++) {
			// 		let data = (res.data.value as EquipInfo[])[i] as EquipInfo;
			// 		if (data.ownerSeq === ownerSeq) {
			// 			result.push(data);
			// 		}
			// 	}
			// } else {
			// 	result = res.data.value as EquipInfo[];
			// }

      result = res.data.value;


			this.plainEquipList = (res.data.value.content as EquipInfo[]) ?? [];

	
			this.plainEquipList.sort((a, b) => {
				if (Number(a.engSource) > Number(b.engSource)) return 1;
				else if (Number(a.engSource) < Number(b.engSource)) return -1;
				else return 0;
			});

			this.convertSiteList = this.tempConvertSiteList((res.data.value.content as EquipInfo[]) ?? []);
			return result;
		},
		async getEquipList(engSource: string | undefined = undefined, cityCode: string | undefined = undefined, sigunguCode: string | undefined = undefined, dongCode: string | undefined = undefined) {
      if(engSource === null) engSource = undefined;
      if(cityCode === null) cityCode = undefined;
      if(sigunguCode === null) sigunguCode = undefined;
      if(dongCode === null) dongCode = undefined;

      // console.log(engSource);

      const res = await useCustomFetch<EquipInfo[]>(`/api/v1/equips`, { method: Method.GET, query: { 
        pageNo: 1, 
        pageSize: 5000, 
        engSource: engSource, 
        cityCode : cityCode, 
        sigunguCode: sigunguCode, 
        dongCode: dongCode
      }});

      


      if(res.data.value.content.length === 1){
        this.selectEquipData(res.data.value.content[0].cid);
      }

			// let result = [] as EquipInfo[];
			// if (useUserStore().getUserType === "owner") {
			// 	let ownerSeq = useUserStore().loginUserOwnerInfo?.ownerSeq;
			// 	for (let i = 0; i < (res.data.value as EquipInfo[]).length; i++) {
			// 		let data = (res.data.value as EquipInfo[])[i] as EquipInfo;
			// 		if (data.ownerSeq === ownerSeq) {
			// 			result.push(data);
			// 		}
			// 	}
			// } else {
			// 	result = res.data.value as EquipInfo[];
			// }

			return res.data.value;
		},
		async getEquipListByMap(
			cityCode: string | undefined = undefined,
			sigunguCode: string | undefined = undefined,
			dongCode: string | undefined = undefined,
			prjKind: string | undefined = undefined,
			buildingUsage: string | undefined = undefined,
			prjRegYear: string | undefined = undefined,
			engSource: string | undefined = undefined,
			searchType: string | undefined = undefined,
			searchText: string | undefined = undefined
		) {
			cityCode = cityCode ? cityCode : undefined;
			sigunguCode = sigunguCode ? sigunguCode : undefined;
			dongCode = dongCode ? dongCode : undefined;
			prjKind = prjKind ? prjKind : undefined;
			buildingUsage = buildingUsage ? buildingUsage : undefined;
			prjRegYear = prjRegYear ? prjRegYear : undefined;
			engSource = engSource ? engSource : undefined;
			searchType = searchType ? searchType : undefined;
			searchText = searchText ? searchText : undefined;

			const res = await useCustomFetch(`/api/v1/monitorings/map`, {
				method: Method.GET,
				query: { cityCode, sigunguCode, dongCode, prjKind, buildingUsage, prjRegYear, engSource, searchType, searchText },
			});

			return res.data.value;
		},
		async setSelectSite(siteName: string, cid: string | undefined = undefined) {
      const route = useRoute();

      let tempSite;

      if(cid !== undefined) tempSite = this.convertSiteList.find((v) => v.siteName === siteName && v.cid === cid) as ConvertSiteForm;
      else tempSite = this.convertSiteList.find((v) => v.siteName === siteName) as ConvertSiteForm

      this.selectSiteInfo = tempSite;

      if (this.selectSiteInfo.equipList.length <= 1 || (route.fullPath.includes('mobile') && route.fullPath.includes('equipInfo')) ) {
				await this.selectEquipData(this.selectSiteInfo.equipList[0].cid as string);
			} else {
				this.selectEquipInfo = {};
			}
		},


		tempConvertSiteList(dataList) {
			let data = [] as { cid: string, siteName: string; ownerSeq: number; rtuId: string; latitude: number; longitude: number; siteAddr: string; dongCode: string; equipList: EquipInfo[] }[];

			for (let i = 0; i < dataList.length; i++) {
				let idx = data.findIndex((v) => v.siteName === dataList[i].siteName);
				if (idx === -1) {
					data.push({
            cid: dataList[i].cid as string,
						siteName: dataList[i].siteName as string,
						ownerSeq: dataList[i].ownerSeq as number,
						rtuId: dataList[i].rtuId as string,
						latitude: dataList[i].latitude as number,
						longitude: dataList[i].longitude as number,
						siteAddr: dataList[i].equipAddr as string,
						dongCode: dataList[i].dongCode as string,
						equipList: [dataList[i]],
					});
				} else {
					data[idx].equipList.push(dataList[i]);
				}
			}
			return data;
		},

		async getSiteEquips(engSource: string, cid: string, siteName: string | undefined = undefined) {
			// const res = await useCustomFetch<EquipMonitorInfo[]>(`/api/v1/users/${userSeq}/monitorings/equips/${cid}`);
      const res = await useCustomFetch<EquipInfo>(`/api/v1/equips/${cid}`, { method: Method.GET });
      this.selectEquipInfo = res.data.value;
      return res.data.value;
		},

		async getSiteEquipsSpecInfo(cid: string) {
			const res = await useCustomFetch(`/api/v1/equips/${cid}/component`, { method: Method.GET });
			return res.data.value;
		},

		setEquipCid(equipCid: string) {
			this.equipCid = equipCid;
		},

		async selectEquipData(equipCid: string) {
      if(equipCid === '') {
        this.selectEquipInfo = {};
        return;
      }

      // const res = this.plainEquipList.find(v => v.cid === equipCid);

			const res = await useCustomFetch<EquipInfo>(`/api/v1/equips/${equipCid}`, { method: Method.GET });

			this.selectEquipInfo = res.data.value as EquipInfo;

      return this.selectEquipInfo;
		},
		async resetSelectEquipData() {
      this.selectEquipInfo = {} as EquipInfo;
		},

    async getEquipMeasure(equipCid: string, searchDate: string = moment().format("YYYY-MM-DD")) {
			const res = await useCustomFetch(`/api/v1/equips/${equipCid}/raw-data`, { method: Method.GET, query: { searchDate } });
			
      return res.data.value ?? [];
		},

    async getEquipMonitoring(engSource: string, cid: string | undefined = undefined, siteName: string | undefined = undefined) {
			const res = await useCustomFetch<MonitoringBySourceInfo>(`/api/v1/monitor/by-eng-source`, { method: Method.GET, query: { engSource, cid, siteName } });

			this.selectEquipMonitoringSource = res.data.value as MonitoringBySourceInfo;

			return res.data.value as MonitoringBySourceInfo;
		},

		async getEquipInstallInfo(equipCid: string) {
			const res = await useCustomFetch<EquipInstallInfo>(`/api/v1/equips/${equipCid}/install-info`, { method: Method.GET });
			this.selectEquipInstall = res.data.value as EquipInstallInfo;

			return res.data.value as EquipInstallInfo;
		},

		async getEquipSpecInfo(equipCid: string) {
			const res = await useCustomFetch<EquipComponent>(`/api/v1/equips/${equipCid}/spec-info`, { method: Method.GET });
      // cnosole.log(res.data.value);
			this.selectEquipSpec = res.data.value as EquipComponent;

			return res.data.value as EquipComponent;
		},

		async getEquipRtuInfo(equipRtuID: string) {
			const res = await useCustomFetch(`/api/v1/rtus/${equipRtuID}`, { method: Method.GET });

			this.selectEquipRtu = res.data.value;

			return res.data.value;
		},

		async getEquipMapData(cityCode: string | undefined = undefined, sigunguCode: string | undefined = undefined, dongCode: string | undefined = undefined) {
			const res = await useCustomFetch(`/api/v1/m/monitor/map`, { method: Method.GET, query: { cityCode, sigunguCode, dongCode } });
			this.mapEquipInfoList = res.data.value.equipMapItemInfos

			// this.mapEquipInfoList.sort((a, b) => {
			//   if(a.cid.includes('r') === true){
			//     if(a.cid.includes('r') === true){
			//       return 0;
			//     } else {
			//       return 1;
			//     }
			//   }

			//   if(b.cid.includes('r') === true){
			//     return -1;-
			//   }

			//   return 0;
			// })
			return this.mapEquipInfoList;
		},
    
		async getReportData(ownerSeq: number, baseDate: string, engSource: string, siteName: string | undefined = undefined, cid: string | undefined = undefined) {
			const res = await useCustomFetch<MobileReportInfo>(`/api/v1/m/report`, { method: Method.GET, query: { ownerSeq, baseDate, engSource, siteName, cid } });
			return res.data.value;
		},

		async getEquipContractData(cid: string) {
			const res = await useCustomFetch<ContractInfo>(`/api/v1/equips/${cid}/contract-info`, { method: Method.GET });

			return res.data.value;
		},
		async getEquipOwnerInfo(ownerSeq: number, cid: string) {
			const res = await useCustomFetch<OwnerInfo>(`/api/v1/owners/${ownerSeq}`, { method: Method.GET, query: {cid} });
			return res.data.value;
		},
		async getEquipManagerInfo(cid: string, companyKind: string | undefined = undefined) {
			const res = await useCustomFetch<ManagerInfo[]>(`/api/v1/equips/${cid}/manager-info`, { method: Method.GET, query: { companyKind } });

			return res.data.value as ManagerInfo[];
		},
		async getForeCasts(engSource: string, siteName:string | undefined = undefined, cid: string | undefined = undefined, searchDate: string) {
			const res = await useCustomFetch(`/api/v1/forecasts/prod`, { method: Method.GET, query: { engSource, siteName, cid, searchDate } });

			return res.data.value;
		},

	},
});
